import '../styles/index.scss';

import { backgroundSVG } from './includes/background-svg';

(() => {
  const backgroundEl = document.querySelector('.background');
  const body = document.querySelector('body');
  const BG_RATIO = 0.2666;

  backgroundEl.innerHTML = backgroundSVG;

  resizeBg();

  window.addEventListener('resize', () => {
    resizeBg();
  });

  function resizeBg() {
    let clientWidth = document.documentElement.clientWidth;
    if (clientWidth < 600) {
      clientWidth = clientWidth * 1.5;
    }
    backgroundEl.children[0].setAttribute('width', clientWidth + 'px');
    backgroundEl.children[0].setAttribute(
      'height',
      BG_RATIO * clientWidth + 'px'
    );
  }

  setTimeout(() => {
    body.classList = [];
  }, 100);
})();
